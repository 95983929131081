<template lang="pug">
Dialog.ta-dialog(:visible.sync="show" :modal='true' :contentStyle="{overflow:'auto !important'}" header='Adicionar Observações' :closable='false')
    ImprimirPrecosPdf(
        @finished="waitingImprimirPdf = false; show = false;"
        :procedimentos="selecionados"
        :observacoes="model.obs"
        :estabsUnificar="model.estabsUnificar"
        :estabsAgrupar="model.estabsAgrupar"
        :mainEstab="model.mainEstab"
        :eventBus="eventBus")
    form(@submit.prevent='imprimirSelecionados()')
        fieldset.p-fluid
            legend.text-title Unificar Estabelecimentos

            label.form-label(for="ta-dialog") Estabelecimentos:
            MultiSelect(
                :options='options.estabsUnificar'
                placeholder='Opcional'
                optionLabel='text'
                dataKey='value'
                :filter='true'
                v-model='model.estabsUnificar'
            )

            label.form-label(for="ta-dialog") Estabelecimento Principal:
            Dropdown(
                :options='model.estabsUnificar'
                placeholder='-- Selecione --'
                optionLabel='text'
                optionValue='value'
                dataKey='value'
                :disabled='!model.estabsUnificar.length'
                v-model='model.mainEstab'
            )

        fieldset.p-fluid
            legend.text-title Agrupar Estabelecimentos

            div(:class="{ 'form-group--error': submitted && $v.model.estabsAgrupar.$error }")
                label.form-label(for="ta-dialog") Estabelecimentos:
                MultiSelect(
                    :options='options.estabsUnificar'
                    placeholder='Opcional'
                    optionLabel='text'
                    dataKey='value'
                    :filter='true'
                    v-model='$v.model.estabsAgrupar.$model'
                )
                .feedback--errors(v-if='submitted && $v.model.estabsAgrupar.$error')
                    .form-message--error(v-if="!$v.model.estabsAgrupar.minLength") É necessário ter pelo menos <b>2 estabelecimentos</b> selecionados.

        label.form-label(for="ta-dialog") Observações:
        Textarea.textarea-observacoes(v-model="model.obs" id='ta-dialog')
        .ta-center.mt-4
            ProgressSpinner.waiting-print-pdf(v-if="waitingImprimirPdf" strokeWidth="6")
            div(v-else)
                Button.p-button-primary.mr-2(label="Imprimir" icon="jam jam-printer" type="submit")
                Button.p-button-secondary.mr-2(label="Cancelar" @click="show = false" type="button")
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
    import Textarea from 'primevue/textarea';
    import ImprimirPrecosPdf from "@/components/PrecoProcedimentos/ImprimirPrecosPdf"
    import Vue from "vue";
    import MultiSelect from 'primevue/multiselect'
    import ProgressSpinner from 'primevue/progressspinner'

    import { Estabelecimento } from './../../middleware'
    import { pCalendarLocale_ptbr } from './../../utils'
    import { minLength } from 'vuelidate/lib/validators';

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Calendar, DataTable, Column,
            ConfirmDialog, Textarea, ImprimirPrecosPdf, MultiSelect, ProgressSpinner,
        },
        props: [
            'display',
            'selecionados',
        ],
        computed: {
            show: {
                get() { 
                    if (!this.display) this.resetModel()
                    else this.getEstabelecimentos()
                    return this.display; 
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        watch: {
            'model.estabsUnificar'() {
                if (this.model.estabsUnificar.findIndex(estab => estab.value === this.model.mainEstab) === -1) {
                    this.model.mainEstab = this.model.estabsUnificar.length ? this.model.estabsUnificar[0].value : null;
                }
            }
        },
        data () {
            return {
                model: {
                    estabsUnificar: [],
                    estabsAgrupar: [],
                    mainEstab: null,
                    obs: '',
                },
                options: {
                    estabelecimentos: [],
                    estabsUnificar: [],
                },
                eventBus: new Vue(),
                ptbr: pCalendarLocale_ptbr,
                submitted: false,
                waitingImprimirPdf: false,
            }
        },
        validations () {
            let v = {
                model: {
                    estabsAgrupar: { minLength: minLength(2) },
                }
            }
            
            return v
        },
        methods: {
            getEstabelecimentos(){
                this.options.estabelecimentos = []
                this.options.estabsUnificar = []
				Estabelecimento.findAllClean({order: 'nm_fantasia'}, {usarCidade: true}).then(response => {
					if (response.status === 200) {
						response.data.forEach(e => {
							this.options.estabelecimentos.push({ text: e.nm_fantasia, value: e.id })
						})
                        this.options.estabsUnificar = this.options.estabelecimentos.filter(
                            estab => this.selecionados.findIndex(
                                procEstab => procEstab.cd_estabelecimento === estab.value
                            ) !== -1);
                        this.model.estabsUnificar = [];
					}
				})
			},
            resetModel() {
                this.model.estabsUnificar = []
                this.model.obs = ''
                this.model.estabsAgrupar = []
                this.model.mainEstab = null
                this.eventBus = new Vue()
                this.submitted = false
            },
            imprimirSelecionados() {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0

				this.waitingImprimirPdf = true;
				// To emit event in next cycle
				setTimeout(() => this.eventBus.$emit('print-pdf'));
			},
        },
    }
</script>

<style lang="scss">

.textarea-observacoes {
    resize: none;
    height: 10rem;
    width: 100%;
    padding: 1%;
}
.waiting-print-pdf {
    width: 25px;
    height: auto;
    margin-inline: 22px;
}
.ta-dialog {
    width: 35rem;
    margin: 10%;
    @media screen and (max-width:600px){
        width: inherit;
    }
    .p-multiselect, .p-dropdown {
        display: inline-grid;
    }
}
</style>
