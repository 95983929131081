<template>
	<div class="preview-pdf" style="display: none">
		<div class="preview-pdf">
			<div id="contentPdfId" class="pdf-content" v-show="procedimentos.length">
				<div class="ta-center">
					<h1 class="title mb-2">ORÇAMENTOS</h1>
				</div>
				<template v-for="estabelecimento, idx in list">
					<table style="border: 2px solid" class="tbl" :key="estabelecimento.cd_estabelecimento">
						<tr class="header avoid-break" :key="estabelecimento.cd_estabelecimento">
							<td :colspan="estabelecimento.cd_estabelecimento ? 4 : 5" class="td-as-header avoid-break">
								<table class="tbl-in-td">
									<tr class="header avoid-break">
										<td colspan="4" style="width: 95%; font-size: 19px; text-align: left" >
											<b>ORÇAMENTO {{ idx + 1 }}</b>
                                        </td>
									</tr>
									<tr class="header avoid-break" :key="estabelecimento.cd_estabelecimento">
										<td colspan="4" style="border-top: none; border-right: none; text-align: left;">
											<span style="font-size: 15px;"> 
												Realizar em: <b>{{ estabelecimento.cd_estabelecimento ? estabelecimento.nm_estabelecimento : 'Diversos estabelecimentos'}}</b>
											</span> <br>
											<span v-if="estabelecimento.cd_estabelecimento"> {{ estabelecimento.ds_endereco }}, <b>{{ estabelecimento.nr_contato }}</b> </span>
											<span v-else> Lista de itens: <b>{{ estabelecimento.procedimentos.length }}</b> </span>
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr class="header avoid-break" :key="`cols_${estabelecimento.cd_estabelecimento}`">
							<td style="width: 7%; text-align: center"><b>-</b></td>
							<td :style="!estabelecimento.cd_estabelecimento ? 'width: 50%' : 'width: 70%'"><b>Procedimento</b></td>
							<td v-if="!estabelecimento.cd_estabelecimento" style="width: 50%;"><b>Estabelecimento</b></td>
							<td style="width: 8%; text-align: center"><b>Qtd.</b></td>
							<td style="width: 15%; text-align: center"><b>Preço (R$)</b></td>
						</tr>
						<tr :class="['avoid-break', {'item-indisponivel': procedimento.nr_quantidade == 0}]" v-for="(procedimento, index) in estabelecimento.procedimentos" :key="procedimento.id">
							<td style="text-align: center">{{ index + 1 }}</td>
							<td >
								<span><p style="margin:2px 0">{{ procedimento.cd_cbhpm }} - {{ procedimento.nm_procedimento }}</p></span>
							</td>
							<td v-if="!estabelecimento.cd_estabelecimento">{{ procedimento.nm_estabelecimento }}</td>
							<td style="text-align: center">{{ procedimento.nr_quantidade }}</td>
							<td style="text-align: right">{{ procedimento.nr_quantidade > 0 ? procedimento.nr_valor_total_f : 'Indisponível' }}</td>
						</tr>
						<tr class="footer avoid-break" style="background-color: #D3D3D3">
                            <td :colspan="!estabelecimento.cd_estabelecimento ? 3 : 2" class="ta-right" style="border-right: 1px solid"></td>
							<td colspan="2" class="ta-center"
                                style="font-size: 17px; border: 1px solid; border-collapse: collapse; text-align: center;">
								<b> Total: <br>{{ formatPrice(estabelecimento.estab_valor_total) }} </b>
							</td>

						</tr>

					</table>
					<div :key="`br${estabelecimento.cd_estabelecimento}`" style="height: 20px;"></div>
				</template>
				<div class="avoid-break">
					<div v-if="estabsAgrupar.length > 0">
						<label for="estabelecimentosAgrupados" >
							<b v-text="'ENDEREÇOS'"></b>
						</label>
						<div id="estabelecimentosAgrupados"  v-for="estab in options.estabelecimentosAgrupados" :key='estab.cd_estabelecimento'>
							<p class="comments">
								<b>Nome:</b> {{ estab.nm_estabelecimento }} <br>
								<b>Contato:</b> Telefone/Whatsapp: {{ estab.estab_telefone }} <br>
								<b>Endereço:</b> {{ estab.ds_endereco }}
							</p>
						</div>
					</div>
				</div>
				<div class="avoid-break" v-if="observacoes">
					<br>
					<label for="p-obs" >
						<b v-text="'Observações do Orçamento:'"></b>
					</label>
					<div class="obs" id="p-obs">
						<p class="comments">{{ observacoes }}</p>
					</div>
				</div>
				<div class="avoid-break">
					<br>
					<label for="formasPagamento">
						<b v-text="'Formas de Pagamento:'"></b>
					</label>
					<div class="obs" id="formasPagamento">
						<h4>► PRESENCIAL:</h4>
						<ul>
							<li>
								Na recepção do <b>MEDCLUB 1</b>(Rua Paissandu, 1819, Centro);
							</li>
							<li>
								Na recepção do <b>MEDCLUB 2</b>(Rua São Pedro, 2265, Centro);
							</li>
							<li>
								Aceitamos todos os Cartões de <b>Crédito</b> ou <b>Débito</b>, <b>Espécie</b> ou <b>PIX</b>;
							</li>
							<li>
								<b>Parcelamento</b> a partir de R$100, via CREDISHOP em até 6x s/ juros e demais em até 10x c/ juros.
							</li>
						</ul>
						<h4>► ONLINE:</h4>
						<ul>
							<li>
								No <b>Aplicativo</b> ou <b>Site</b>;
							</li>
							<li>
								Pagamento no Cartão de <b>Crédito(EXCETO CREDISHOP)</b> ou <b>PIX</b>.
							</li>
						</ul>
					</div>
				</div>
				<div style="text-align: center">Esse orçamento é válido por 24 horas desde a data de emissão.</div>
			</div>
		</div>
	</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import { logoMedclubBase64, fixTelefoneFormat } from './../../utils';

export default {
	props: ['procedimentos', 'observacoes', 'eventBus', 'estabsUnificar', 'mainEstab', 'estabsAgrupar',],
	created() {
		let usuario = JSON.parse(localStorage.getItem('usuario'));
		this.nomeUsuario = `${usuario?.firstName} ${usuario?.lastName}`;
	},
	mounted() {
		this.eventBus.$on('print-pdf', this.gerarPdf)
	},
	data() {
		return {
			list: [],
			waiting: false,
			logo: null,
			nomeUsuario: '',
			options: {	
				estabelecimentosAgrupados: [],
			},
		}
	},
	methods: {
		verificaNomeMedclub (procedimento) {
			return procedimento.nm_estabelecimento.toLowerCase().includes('medclub')
		},
		gerarPdf () {
			this.list = this.agruparProcedimentos();
			let element = document.getElementById('contentPdfId');
			let opt = {
				margin:       [25, 10, 17, 10],
				filename:     'orcamento-exames.pdf',
				image:        { type: 'jpeg', quality: 0.98 },
				html2canvas:  { dpi: 96, scale: 2, scrollX: 0, scrollY: 0 },
				jsPDF:        { unit: 'mm', format: 'letter', orientation: 'portrait' },
				pagebreak:    { avoid: '.avoid-break' },
			}

			html2pdf().set(opt).from(element).toPdf().get('pdf').then((pdf) => {
				let fontSize = 8;
				let pageWidth = pdf.internal.pageSize.getWidth();
				let pageHeight = pdf.internal.pageSize.getHeight();
				let totalPages = pdf.internal.getNumberOfPages();
				let userNameText = `usuário: ${ this.nomeUsuario }`
				let userNameTextWidth = pdf.getStringUnitWidth(userNameText) * fontSize / pdf.internal.scaleFactor;
				let printDateTxt = `Impresso em: ${moment(new Date()).format('DD/MM/YYYY HH:mm')}`

				for (let i = 1; i <= totalPages; i++) {
					pdf.setPage(i);
					pdf.setFontSize(fontSize);
					pdf.addImage(logoMedclubBase64, 'png', pageWidth / 2 - 20, 10, 40, 8);
					pdf.line(15, pageHeight - 13, pageWidth - 15 , pageHeight - 13);
					pdf.text('Page ' + i + ' of ' + totalPages, pageWidth - 30, pageHeight - 10);
					pdf.text(userNameText, (pageWidth - userNameTextWidth) / 2, pageHeight - 10);
					pdf.text(printDateTxt, 15, pageHeight - 10);

					// Borda superior das células que iniciam uma proxima página
					if (i > 1) {
						pdf.setDrawColor(153, 153, 153)
						pdf.line(10.2, 25, pageWidth - 10.2 , 25);
					}
				}
			}).output('bloburl').then((result) => {
				window.open(result)
			}).finally(() => this.$emit('finished'))
		},
		unificarEstabelecimentos() {
			const estabsIdsUnificar = this.estabsUnificar.map(estab => estab.value);
			const estabsIdsAgrupar = this.estabsAgrupar.map(estab => estab.value);
			const main = this.procedimentos.find(proc => proc.cd_estabelecimento === this.mainEstab);

			if (this.estabsAgrupar) {
				this.procedimentos.map(proc => {
					const result = Object.assign({}, proc);
					if (estabsIdsAgrupar.includes(result.cd_estabelecimento)) {
						if ( this.options.estabelecimentosAgrupados.findIndex(
							estab => estab.cd_estabelecimento === result.cd_estabelecimento
						) === -1) this.options.estabelecimentosAgrupados.push(result)
						proc.cd_estabelecimento = null
					}
				})
			}

			if (!main) return [...this.procedimentos];

			// Substituir info de estabelecimento pelas info do estabelecimento principal nos itens a serem unificados
			return this.procedimentos.map(proc => {
				const result = Object.assign({}, proc);
				if (estabsIdsUnificar.includes(result.cd_estabelecimento)) {
					result.cd_estabelecimento = main.cd_estabelecimento;
					result.nm_estabelecimento = main.nm_estabelecimento;
					result.estab_bairro = main.estab_bairro;
					result.estab_cep = main.estab_cep;
					result.estab_complemento = main.estab_complemento;
					result.estab_numero = main.estab_numero;
					result.estab_rua = main.estab_rua;
					result.estab_telefone = main.estab_telefone;
					result.estab_whatsapp_cliente = main.estab_whatsapp_cliente;
				}
				return result;
			});
		},
		agruparProcedimentos() {
			let list = ( this.estabsUnificar.length || this.estabsAgrupar.length ) ? this.unificarEstabelecimentos() : [...this.procedimentos];

			let agrupados = {};

			list.forEach(val => {
				if (! agrupados[val.cd_estabelecimento]) {
                    const telefone = val.estab_telefone ? `telefone: ${fixTelefoneFormat(val.estab_telefone)}` : '';
                    const whatsapp = val.estab_whatsapp_cliente
                        ? `whatsapp: ${fixTelefoneFormat(val.estab_whatsapp_cliente)}`
                        : '';
                    const contato = `${telefone}${telefone && whatsapp ? ', ' : ''}${whatsapp}`

					agrupados[val.cd_estabelecimento] = {
						cd_estabelecimento: val.cd_estabelecimento,
						nm_estabelecimento: val.nm_estabelecimento,
						ds_endereco: val.ds_endereco ? val.ds_endereco : `${val.estab_rua}, ${val.estab_numero}, ${val.estab_bairro}, ${val.estab_cep}`,
                        nr_contato: contato,
						estab_valor_total: 0,
						procedimentos: []
					}
				}
				agrupados[val.cd_estabelecimento].procedimentos.push({
					id: val.id,
					cd_cbhpm: val.cd_cbhpm,
					ds_sigla: val.ds_sigla,
					nm_procedimento: val.nm_procedimento,
					ds_sinonimia: val.ds_sinonimia,
					ds_procedimento: val.ds_procedimento,
					nr_quantidade: val.nr_quantidade,
					nr_valor_total_f: val.nr_valor_total_f.substr(2),
					nm_estabelecimento: val.nm_estabelecimento,
				})
				agrupados[val.cd_estabelecimento].estab_valor_total += val.nr_valor_total
			})

			let agrupados_list = Object.values(agrupados);
			// Ordena os estabelecimentos
			agrupados_list.sort((a, b) => {
				if (this.verificaNomeMedclub(a) && !this.verificaNomeMedclub(b)) return -1;
				if (a.nm_estabelecimento < b.nm_estabelecimento) return -1;
				if (a.nm_estabelecimento > b.nm_estabelecimento) return 1;
				return 0;
			})
			return agrupados_list;
		},
		formatPrice (val) {
			return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
		}
	}
}
</script>

<style scoped>
.preview-pdf {
	width: 96%;
	height: 90%;
}
.pdf-content {
	width: 100%;
}
.title {
	font-weight: 700;
	font-size: 15px;
}
.tbl {
	width: 100%;
	border-collapse: collapse;
}
.tbl .header, .footer {
	background-color: #f1f1f1;
}
.obs{
	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	text-align:left;
	border: 1px solid #999;
	padding: 0 1%;
}
.tbl td {
	padding: 2px 4px;
	font-size: 13px;
	border-right: 1px solid #999;
	border-bottom: 1px solid #999;
}
.tbl > tr > td:first-child {
	border-left: 1px solid #999;
}
.td-as-header {
	margin: 0;
	padding: 0;
	border: 1px solid #999 !important;
}
.tbl-in-td {
	width: 100%;
	border-collapse: collapse;
	padding: 0;
	border: none;
}
.tbl-in-td td {
	padding: 2px 4px;
	font-size: 13px;
	border: none;
}
.total-value {
    font-size: 17px;
    border: 1px solid;
    border-collapse: collapse;
    text-align: center;
}
.comments {
    white-space: pre-line;
}
.item-indisponivel {
	color:#e91224 !important;
}
</style>
